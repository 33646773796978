import { config } from '../../../config';
import { concatUrl } from '.';

export const getContractApiUrl = (): string => {
    return concatUrl([
        config.backend.baseUrl,
        config.backend.rest.url,
        config.backend.rest.listUrl,
        '/publicfolder/contract',
    ]);
};
